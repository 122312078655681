/**
* Generic form styling
**/

.form {
    margin-top: $inuit-base-spacing-unit*3 !important;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    @include media-query(lap-and-up) {
        margin-top: 0;
    }
}

.form__fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

.form__legend {
    // Form legend
}

.form__textbox,
.form__textarea,
.form__select,
.form__select--multiple {
    //Set the placeholder text
    @include placeholder($white);

    width: 100%;
    height: 44px;
    line-height: 44px;

    margin-bottom: 32px;
    padding-left: 8px;

    background-color: $dark-grey;
    border: none;
    border-bottom: 3px solid $red;

    transition: background-color .3s ease;

    &:focus {
        outline: 0;
        background-color: lighten($dark-grey, 10%);
        color: $white;
    }
}

.form__textarea {
    height: 120px;
    margin-bottom: 26px;

    resize:vertical; //Allow vertical resizing only
}

.form__select {
    // Form select boxes
}

.form__select--multiple {
    min-height: 200px;
    padding: 0;

    option {
        padding: $inuit-base-spacing-unit/4;
        margin-bottom: $inuit-base-spacing-unit/4;
    }
}

.form__checkbox {
    display: none;

    & + label {
        text-indent: -9999em;
        //background-color: $light-grey; Set the bg color
        height: 12px;
        width: 12px;
        display: inline-block;
        padding: 0;
        margin: 0 $inuit-base-spacing-unit/3 0 0;
    }

    &:checked + label {
        //background-color: $blue; Set the highlighted (checked) colour
    }
}

.form__radio {
    display: none;

    & + label {
        text-indent: -9999em;
        // background-color: $light-grey; set the bg color
        height: 12px;
        width: 12px;
        display: inline-block;
        padding: 0;
        margin: 0 $inuit-base-spacing-unit/3 0 0;
        border-radius: 50%;
    }

    &:checked + label {
        //background-color: $blue; Set the highlighted (checked) colour
    }
}

.form__button {
    //Form buttons
}

/** Some Parsley Validation **/
// input.parsley-success,
// select.parsley-success,
// textarea.parsley-success {
//   color: #468847;
//   background-color: #DFF0D8;
//   border: 1px solid #D6E9C6;
// }

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
  color: #B94A48;
  background-color: #a77f7f;
  border: 1px solid #EED3D7;
}

.form ul.parsley-errors-list {
    @include inuit-font-size(13px, 0.9);
    color: #B94A48;
    text-align: right;

    margin-top: -18px;
    margin-bottom: 18px;
    padding: 0;

    list-style-type: none;
    opacity: 0;

    transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    -moz-transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in;

    &.filled {
      opacity: 1;
    }
}

//Format responses
.form__response {
    margin-bottom: $inuit-base-spacing-unit;
    padding: $inuit-base-spacing-unit;
    border: 2px solid white;

    p {
        font-weight: 700;
        color: white;
    }
}