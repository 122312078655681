/**
Generic listing component
**/


.listing {
    margin-top: $inuit-base-spacing-unit*3;
}

.listing__item {
    margin-bottom: $inuit-base-spacing-unit*2;

    @include inuit-font-size(16px);
    text-transform: uppercase;
    font-weight: 400;

    opacity: 0;

    transition: opacity .4s linear;
}

//Add transition delay for each child element with funky loop
@for $i from 2 through 9 {
  .listing__item:nth-child(#{$i}) {
    transition-delay: .2s * ($i - 1);
  }
}


.show-me {
    opacity: 1;
}

.listing__icon {
    display: block;

    width: 100px;
    height: 100px;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: $inuit-base-spacing-unit/2;
}

/**
* Project container
**/
.projects {
    width: 72%;
    margin-left: auto;
    margin-right: auto;

    @include media-query(lap-and-up) {
        width: 85%;
    }
}
.project__content {
    padding-right: $inuit-base-spacing-unit;
}

.project__thumbnail {
    background-color: $dark-grey;
}

.project__thumbnail img {
    opacity: 1;

    transition: opacity 0.4s ease;
}

.project__thumbnail:hover {
    img {
        opacity: 0.5;
    }
}

.project__title {
    position: relative;

    color: $dark-grey;
    text-transform: uppercase;

    &:after {
        content: '';

        position: absolute;
        bottom: 0;
        left: 0;

        width: 200px;
        height: 4px;

        background-color: $red;
    }
}