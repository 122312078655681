/*
* Define some default wrappers and panels
*/

body {
    background-color: $dark-grey;
}

.wrapper {
    position: relative;
    top: 0;
    bottom: 100%;
    left: 0;

    min-width: 100%;
    min-height: 100%;

    z-index: 1;
}

.container {
    position: relative;
    width: 96%;

    margin: 0 auto;

    z-index: 2;

    @include media-query(lap-and-up) {
        max-width: 1300px;
    }

    //Remove the margin on the last paragraph
    p:last-child {
        margin-bottom: 0;
    }
}

.container--745 {
    max-width: 745px;
}

.container--1140 {
    max-width: 1140px;
}

.panel {
    position: relative;

    width: 100%;
    height: auto;
    z-index: 100;
}

.panel--white {
    background-color: white;
}

.panel--dark-grey {
    background-color: $dark-grey;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li,
    a {
        color: $white;
    }
}



hr {
    width: auto;
    max-width: 200px;

    margin: 0 auto;
    border-bottom-width: 4px;
}

/* More random stuff that I can't be arsed to create a file for **/
.project__thumbnail {
    >a {
        display:block;
        &.im-a-video-me {
            position:relative;
            &:after {
                content:'';
                position:absolute;
                height:50px;
                width:50px;
                background: url($imgPath+'play-arrow.svg') no-repeat;
                top:50%;
                left:0;
                right:0;
                margin:auto;
                transform:translateY(-50%);
            }
        }
    }
}
.project__content p {
    margin-bottom:0;
    &:last-of-type {
    margin-bottom:1em;
    }

}
.container {
    h2 {
        position: relative;
        font-weight: 400;
        text-transform: uppercase;

        padding-bottom: $inuit-base-spacing-unit;

        &:after {
            content: '';

            position: absolute;
            left: 50%;
            bottom: 0;

            width: 200px;
            height: 4px;

            margin-left: -100px;

            background-color: $red;

        }
    }
}

/** Style up the 404 page **/

.error404 {
    background-color: $black;
    width: 100%;
    height: 100%;
}

.not-found__stuff {
    @include vertical-align;
}

.error404 h1 {
    color: white;
    text-align: center;
    @include inuit-font-size(28px);
}

.error404 p {
    color: white;
    text-align: center;

    margin: 2em auto;
    @include inuit-font-size(18px);
}

.error404 a {
    color: white;

    text-decoration: underline;
}