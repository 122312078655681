/*------------------------------------*\
    #Font sizing
\*------------------------------------*/

//Define some class overrides to match heading sizes
.alpha {
    @include inuit-font-size($inuit-heading-size-1);
}

.beta {
    @include inuit-font-size($inuit-heading-size-2);
    font-weight: 400;
    text-transform: uppercase;
}

.gamma {
    @include inuit-font-size($inuit-heading-size-3);
}

.delta {
    @include inuit-font-size($inuit-heading-size-4);
}

.epsilon {
    @include inuit-font-size($inuit-heading-size-5);
}

.digamma {
    @include inuit-font-size($inuit-heading-size-6);
}