/**
* Define the main font stack and headings
**/

$openSans: 'Open Sans', sans-serif;
$lato: 'Lato', sans-serif;

body {
    font-family: $openSans;
}

.open-sans {
    font-family: $openSans;
}

.lato {
    font-family: $lato;
}

p {
    @include inuit-font-size(16px);
}

p.large {
    @include inuit-font-size(20px, 1.4);
}

a {
    //Set a transparent border than we can the use to fade in on hover
    //to fake the text-decoration effect
    text-decoration: none;
    border-bottom: 1px solid transparent;

    transition: border-color 0.5s ease-in-out, color 0.5s ease-in-out;

    color: $red;

    &:hover {
        color: darken($red, 10%);
        border-color: $grey;
    }
}