/**
* Define the navigation components
**/
.nav {
    margin-top: $inuit-base-spacing-unit*0.75;

    @include media-query(palm) {
        margin-top: $inuit-base-spacing-unit;
    }

    @include media-query(lap-and-up) {
        margin-top: 0;
    }

    ul {
        text-align: center;

        @include media-query(lap-and-up) {
            text-align: right;
        }
    }

    li {
        text-transform: uppercase;
    }

    li:not(.last) {
        margin-right: 12px;

        @include media-query(lap-and-up) {
            margin-right: 30px;
        }
    }

    a {
        @include inuit-font-size(16px);
        color: white;

        @include media-query(palm) {
            @include inuit-font-size(14px);
        }
    }

    a:hover {
        border-color: white;
    }
}