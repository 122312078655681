.pin {
    position: relative;
    overflow: hidden;
    .slick-arrow {
        display: block;
        height: 100%;
        width: 30%;
        left:auto;
        right: auto;
        top:0;
        transform:none;
        opacity: 0;
        transition:0.6s;
        &:hover {
            opacity: 1;
        }
        &:before {
            display:none;
        }
        &.slick-prev {
            background:linear-gradient(to left, transparent 0%, transparent 50%, rgba(255,255,255,0.2) 100%);
            left:0;
        }
        &.slick-next {
            background:linear-gradient(to right, transparent 0%, transparent 50%, rgba(255,255,255,0.2) 100%);
            right:0;

        }
    }
}

.slider__item {
    position: relative;
    overflow: hidden;
}
.bg {
    position: absolute;

    width: 100%;
    height: 100%;

    z-index: 1;

    opacity: 0.95;

    background-color: $dark-grey;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.carousel {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    z-index: 2;
}

.carousel__flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel__inner {
    width: 208px;

    @include media-query(lap-and-up) {
        width: 400px;
    }

    @include media-query(desk) {
        width: 545px;
    }

    h2 {
        @include inuit-font-size(64px);

        @include media-query(lap-and-up) {
            @include inuit-font-size(124px);
        }

        @include media-query(desk) {
            @include inuit-font-size(170px);
        }
    }

    span.in {
        display: inline-block;
    }
}

.carousel__control {
    margin-top: $inuit-base-spacing-unit;

    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;

        @include media-query(lap-and-up) {
            width: 35px;
            height: 35px;
        }

        path {
            fill: white;
        }
    }
}

.carousel__control--next {
    margin-left: $inuit-base-spacing-unit*2;

    @include media-query(lap-and-up) {
        margin-left: $inuit-base-spacing-unit*3;
    }

    @include media-query(desk) {
        margin-left: $inuit-base-spacing-unit*6;
    }
}

.carousel__control--prev {
    margin-right: $inuit-base-spacing-unit*2;

    @include media-query(lap-and-up) {
        margin-right: $inuit-base-spacing-unit*3;
    }

    @include media-query(desk) {
        margin-right: $inuit-base-spacing-unit*6;
    }
}


/** Projects carousel - using slick **/
.slick-arrows {
    position: relative;
    height: 30px;
}


/*Pop Effect*/
.pop-effect{
    display: inline;
    //text-indent: 80x;
}
.pop-effect span{
    animation: pop 21s linear infinite;
    color: $white;
    opacity: 0;
    overflow: hidden;
    position: absolute;
}
.pop-effect span:nth-child(2){
    animation-delay: 7s;
}
.pop-effect span:nth-child(3){
    animation-delay: 14s;
}

/*Pop Effect Animation*/
@keyframes pop{
    0% { opacity: 0; -webkit-transform: rotate(0deg) skew(0deg) translate(0px);}
    3% { opacity: 1; -webkit-transform: translateY(0px); }
    33% { opacity: 1; -webkit-transform: translateY(0px); }
    36% { opacity: 0; -webkit-transform: translateY(0px); }
    100% { opacity: 0; }
}
// @-moz-keyframes pop{
//     0% { opacity: 0; }
//     5% { opacity: 0; -moz-transform: rotate(0deg) scale(0.10) skew(0deg) translate(0px); }
//     10% { opacity: 1; -moz-transform: translateY(0px); }
//     25% { opacity: 1; -moz-transform: translateY(0px); }
//     30% { opacity: 0; -moz-transform: translateY(0px); }
//     80% { opacity: 0; }
//     100% { opacity: 0;}
// }
// @-ms-keyframes pop{
//     0% { opacity: 0; }
//     5% { opacity: 0; -ms-transform: rotate(0deg) scale(0.10) skew(0deg) translate(0px); }
//     10% { opacity: 1; -ms-transform: translateY(0px); }
//     25% { opacity: 1; -ms-transform: translateY(0px); }
//     30% { opacity: 0; -ms-transform: translateY(0px); }
//     80% { opacity: 0; }
//     100% { opacity: 0; }
// }
