/**
* Additional mixins
**/

// Style form placeholder text
@mixin placeholder($col) {
    &::-webkit-input-placeholder { /* Safari, Chrome and Opera */
        color: $col;
    }

    &:-moz-placeholder { /* Firefox 18- */
        color: $col;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        color: $col;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        color: $col;
    }

    &::-ms-input-placeholder { /* Edge */
        color: $col;
    }

    &:placeholder-shown { /* Standard one last! */
        color: $col;
    }
}

/*
 * Vertical align
 */
@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}


@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

/** Maintain aspect ratio for element **/
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}