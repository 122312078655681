/* ------------------------------------*\
    #Settings
\*------------------------------------ */
$inuit-base-text-color: #333;
$inuit-base-font-size: 16px;

$imgPath: '/theme/powertrain/assets/img/';

$colours: (
    "black"     : #36333a,
    "white"     : #fff,
    "dark-grey" : #2a2a2a,
    "grey"      : #e0dfe2,
    "light-grey": #979797,
    "red"       : #ff0000
);

$black: map-get($colours, 'black');
$white: map-get($colours, 'white');
$dark-grey: map-get($colours, 'dark-grey');
$grey: map-get($colours, 'grey');
$light-grey: map-get($colours, 'light-grey');
$red: map-get($colours, 'red');

$breakpoints:
    (
        "palm"          "screen and (max-width: 33.125em)",
        "palm-mid"      "screen and (min-width: 33.15em) and (max-width: 44.9375em)",
        "lap"           "screen and (min-width: 45em) and (max-width: 63.9375em)",
        "lap-and-up"    "screen and (min-width: 45em)",
        "portable"      "screen and (max-width: 74.9375em)",
        "desk"          "screen and (min-width: 64em)",
        "mega"          "screen and (min-width: 100em)",
        "retina"        "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)"
    ) !default;

@import "node_modules/inuit-defaults/settings.defaults";
@import "node_modules/inuit-responsive-settings/settings.responsive";

/* ------------------------------------*\
    #Tools
\*------------------------------------ */
@import "node_modules/inuit-functions/tools.functions";
@import "tools.functions";
@import "node_modules/inuit-mixins/tools.mixins";
@import "tools.mixins";
@import "node_modules/inuit-responsive-tools/tools.responsive";
$inuit-use-fractions: false;  //Use one-half, one-quarter etc
@import "node_modules/inuit-tools-widths/tools.widths";

/* ------------------------------------*\
    #Generic
\*------------------------------------ */
@import "node_modules/inuit-normalize/generic.normalize";
@import "node_modules/inuit-box-sizing/generic.box-sizing";
@import "node_modules/inuit-reset/generic.reset";
@import "node_modules/inuit-shared/generic.shared";

/* ------------------------------------*\
    #Base
\*------------------------------------ */
$inuit-heading-size-1: 42px;
$inuit-heading-size-2: 22px;
$inuit-heading-size-3: 20px;
$inuit-heading-size-4: 18px;
$inuit-heading-size-5: 16px;
$inuit-heading-size-6: 14px;

@import "node_modules/inuit-page/base.page";
@import "node_modules/inuit-headings/base.headings";
@import "node_modules/inuit-paragraphs/base.paragraphs";
@import "node_modules/inuit-lists/base.lists";
@import "node_modules/inuit-images/base.images";
@import "base.typography";

/* ------------------------------------*\
    #Helpers/Functions
\*------------------------------------ */
@import "tools.helpers";

/* ------------------------------------*\
    #Objects
\*------------------------------------ */
@import "node_modules/inuit-list-bare/objects.list-bare";
@import "node_modules/inuit-list-block/objects.list-block";
@import "node_modules/inuit-list-inline/objects.list-inline";
@import "node_modules/inuit-list-ui/objects.list-ui";
@import "objects.lists";

$inuit-enable-btn--small: true;
$inuit-enable-btn--full: true;
$inuit-btn-padding: quarter($inuit-base-spacing-unit);
$inuit-btn-background: map-get($colours, "white");

@import "node_modules/inuit-buttons/objects.buttons";
@import "objects.buttons";
@import "objects.forms";

//$inuit-table-border-color:      $light-grey;
$inuit-enable-table--compact: true;
$inuit-enable-table--cosy: true;
$inuit-enable-table--comfy: true;
$inuit-enable-table--cells: true;
$inuit-enable-table--rows: true;
$inuit-enable-table--columns: true;
@import "node_modules/inuit-tables/objects.tables";
@import "objects.tables";

/* ------------------------------------*\
    #Layout
\*------------------------------------ */
$inuit-enable-layout--center: true;
$inuit-enable-layout--right: true;
$inuit-enable-layout--small: true;
$inuit-enable-layout--large: true;
$inuit-enable-layout--huge: true;
$inuit-enable-layout--rev: true;
$inuit-enable-layout--flush: true;
$inuit-enable-layout--bottom: true;
@import "node_modules/inuit-layout/objects.layout";
@import "layout.generic";
@import "layout.navigation";
@import "layout.header";
@import "layout.footer";

/* ------------------------------------*\
    #Components
\*------------------------------------ */
@import "components.carousel";
@import "components.slick";
@import "components.listing";
@import "components.modal";
@import "components.testimonial";

.project__content {
    position: relative;
}

/* ------------------------------------*\
    #Trumps
\*------------------------------------ */
@import "node_modules/inuit-clearfix/trumps.clearfix";
$inuit-widths-columns: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
@import "node_modules/inuit-widths/trumps.widths";
$inuit-widths-columns-responsive: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
@import "node_modules/inuit-responsive-widths/trumps.widths-responsive";

$inuit-enable-margins: true;
$inuit-enable-margins--tiny: true;
$inuit-enable-margins--small: true;
$inuit-enable-margins--large: true;
$inuit-enable-margins--huge: true;
$inuit-enable-margins--none: true;
$inuit-enable-paddings: true;
$inuit-enable-paddings--tiny: true;
$inuit-enable-paddings--small: true;
$inuit-enable-paddings--large: true;
$inuit-enable-paddings--huge: true;
$inuit-enable-paddings--none: true;
@import "node_modules/inuit-spacing/trumps.spacing";

$inuit-enable-responsive-margins: true;
$inuit-enable-responsive-margins--tiny: true;
$inuit-enable-responsive-margins--small: true;
$inuit-enable-responsive-margins--large: true;
$inuit-enable-responsive-margins--huge: true;

$inuit-enable-responsive-margins--negative: true;
$inuit-enable-responsive-margins--negative-tiny: true;
$inuit-enable-responsive-margins--negative-small: true;
$inuit-enable-responsive-margins--negative-large: true;
$inuit-enable-responsive-margins--negative-huge: true;

$inuit-enable-responsive-margins--none: true;

$inuit-enable-responsive-paddings: true;
$inuit-enable-responsive-paddings--tiny: true;
$inuit-enable-responsive-paddings--small: true;
$inuit-enable-responsive-paddings--large: true;
$inuit-enable-responsive-paddings--huge: true;

$inuit-enable-responsive-paddings--none: true;
@import "node_modules/inuit-responsive-spacing/trumps.spacing-responsive";

@import "trumps.font-sizing";
@import "trumps.inline-block-fix";
