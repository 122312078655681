/**
* Main header layout
**/

.header {
    position: static;
    width: 100%;

    background:linear-gradient(to bottom, rgba(16,16,16,0.4) 0%, transparent 100%);
    z-index: 2000;
    transition: background-color 0.3s linear;

    @include media-query(lap-and-up) {
        position: fixed;
    }

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;

        width: 70%;
        height: auto;

        @include media-query(lap-and-up) {
            margin-left: 0;
            width: 100%;
            height: auto;
        }
    }
}
