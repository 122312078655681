/**
* Table styling
**/

.table thead {
    background-color: $white;

    th {
        @include inuit-font-size(22px);
        font-weight: 400;
        color: $black;
        text-align: left;

        padding: $inuit-base-spacing-unit/2;
    }
}

.table tbody td {
    font-weight: 400;
    text-align: left;
    padding: $inuit-base-spacing-unit/2;
}

.table tbody tr:nth-child(odd) {
   background-color: $black;
}

.table a {
    color: $black;

    &:hover {
        text-decoration: underline;
    }
}