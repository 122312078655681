/** IE responsive stuff **/
@-ms-viewport {
    width: device-width;
}

body {
    overflow-x: hidden;
}
.cf:before,
.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after {
    clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
    *zoom: 1;
}

/**
 * Add/remove floats
 */
.float--right { float: right !important; }
.float--left { float: left !important; }
.float--none { float: none !important; }

/**
 * Text alignment
 */
.text--left { text-align: left !important; }
.text--center { text-align: center !important; }
.text--right { text-align: right !important; }

/**
 * Text adjustments
 */
.text--bold { font-weight: bold; }
.text--italic { font-style: italic; }
.text--no-transform { text-transform: none; }

/**
*   Centre nested images for responsive stuff
**/
.image--centre,
.image--center {
    display: block;

    margin-left: auto;
    margin-right: auto;
}

.desaturate {
    filter: grayscale(100%);
}

/**
*   Visually hide
**/
.is-visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

/**
* Text colour
**/
@each $colour, $hex in $colours {
    .text--#{$colour} {
        color: $hex;
    }

    a.text--#{$colour} {
        color: $hex;

        &:hover {
            color: $hex;
            text-decoration: none;
        }
    }
}

/**
* Background colours
**/
@each $colour, $hex in $colours {
    .bg--#{$colour} {
        background-color: $hex;
    }
}

/**
* Border colours
**/
@each $colour, $hex in $colours {
    .border--#{$colour} {
        border-color: $hex !important;
    }
}

@each $colour, $hex in $colours {
    .svg-fill--#{$colour} {
        .fill {
            fill: $hex !important;
        }
    }
}

/* Superscript */
span.sup {
    font-weight: normal;
}