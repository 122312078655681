/**
* Additional button styles
**/

.btn,
a.btn {
    @include inuit-font-size(14px);
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    line-height: 36px;

    width: 100%;
    min-height: 36px;

    background-color: transparent;
    border: 3px solid $white;

    padding-top: 0;
    padding-bottom: 0;
    padding-left: 12px;
    padding-right: 12px;

    transition: all .3s ease;

    outline: none;

    &:hover {
        background-color: lighten($dark-grey, 10%);
        color: $white;
    }

    &--dark {
        border-color:$black;
        color:$black;
    }

    &--light {
        border-color: $grey;
        color: $light-grey;
    }
}

.button-container {
    max-width:240px;
    margin:auto;
}