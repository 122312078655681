.panel--testimonials {
    background-color: $dark-grey;
}

.testimonial {
    width: 100%;
    max-width: 70%;

    margin-left: auto;
    margin-right: auto;

    background-color: $dark-grey;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    @include media-query(lap-and-up) {
        max-width: 85%;
    }

    @include media-query(desk) {
        max-width: 85%;
    }

    blockquote {
        margin-bottom: 0;
    }
}

.testimonial__wrapper {
    width: 100%;

    //Set to flexbox to position the quotes
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;

    &--quote {
        justify-content: center;
    }

    @include media-query(lap-and-up) {
        width: 50%;

        &--quote {
            justify-content: flex-end;
        }
    }
}

.testimonial__quote {
    width: 100%;
    max-width: 480px;
    min-height: 400px;

    padding-top: $inuit-base-spacing-unit*2;
    padding-bottom: $inuit-base-spacing-unit*2;
    margin-right: 0;

    @include media-query(lap-and-up) {
        margin-right: $inuit-base-spacing-unit*2;
    }
}

.testimonial__wrapper--image {
    display: none;
    height: 100%;

    @include media-query(lap-and-up) {
        display: block;
    }
}

.testimonial__quote-mark {
    display: block;
    width: 66px;
    height: 66px;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: $inuit-base-spacing-unit*2;

    path {
        fill: $white;
    }

    @include media-query(lap-and-up) {
        display: none;
    }

    @include media-query(desk) {
        display: block;
    }
}

.testimonial__text p {
    @include inuit-font-size(16px, 1.3);
    font-style: italic;
    color: $white;
    text-align: center;
}

.testimonial__cite {
    @include inuit-font-size(14px);
    font-style: bold;
    color: $light-grey;
    text-align: center;

    margin-top: $inuit-base-spacing-unit;
}

.testimonials__arrow--prev {
    left: 0;
}

.testimonials__arrow--next {
    right: 0;
}